import Bed from '../assets/images/pagephotos/room.jpg'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Rest from '../assets/images/pagephotos/ladiko-suites-by-night.jpg'
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const Services = () => {
    const history = useHistory()
    const { t } = useTranslation()
    return (
        <div className="max-w-5xl mx-auto xl:-mt-44 2xl:-mt-0 p-10 space-y-10">
            <h1 className="text-center text-2xl lg:text-3xl font-extrabold">{t('Best Service for you')}</h1>

            <div className="grid lg:grid-cols-2 shadow-xl">
                <div className="inline-block overflow-hidden">
                    <img src={Bed} alt="hotel-room-view" height="auto" width="auto" className="h-full transform hover:scale-125 transition duration-300 ease-in " />
                </div>
                <div className="p-10 text-center space-y-2 lg:space-y-10 bg-white">
                    <p className="lg:text-xl font-bold uppercase">Junior Suites & Family Suites</p>
                    <p className="text-sm lg:text-xl">{t('Luxury and comfortables sea view suites. We make the ordinary, extraordinary.')}</p>
                    <PlayCircleOutlineIcon onClick={() => history.push('/rooms')} className="cursor-pointer text-custom-button transform duration-300 ease-in-out hover:text-yellow-900" fontSize='large' />
                </div>
                <div className="p-10 text-center space-y-2 lg:space-y-10 bg-white">
                    <p className="lg:text-xl font-bold uppercase">Restaurant Sala Pietra</p>
                    <p className="text-sm lg:text-xl">{t('Chase the flavors. An oasis of pleasure. Sala Pietra Restaurant, where the flavor inebriates you')}</p>
                    <PlayCircleOutlineIcon onClick={() => history.push('/salapietra')} className="cursor-pointer text-custom-button hover:text-yellow-900 transition duration-300 ease-in" fontSize='large' />
                </div>
                <div className="inline-block overflow-hidden">
                    <img src={Rest} alt="sala-pietra-by-night" height="auto" width="auto" className="h-full transform hover:scale-125 transition duration-300 ease-in " />
                </div>
            </div>
        </div>
    )
}

export default Services
