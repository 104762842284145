import Image from '../assets/images/gallery/reception-4.jpg'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import Image1 from '../assets/images/gallery/ladiko-suites.jpg'
import Image2 from '../assets/images/gallery/ladiko-drone.jpg'
import Image3 from '../assets/images/gallery/reception-2.jpg'
import Image4 from '../assets/images/gallery/reception-4.jpg'
import Image5 from '../assets/images/gallery/reception-3.jpg'
import Image7 from '../assets/images/gallery/buffe-4.jpg'
import Image8 from '../assets/images/gallery/buffe-2.jpg'
import Image9 from '../assets/images/gallery/buffe-3.jpg'
import Image10 from '../assets/images/gallery/buffe-5.jpg'
import Image11 from '../assets/images/gallery/buffe.jpg'
import Image12 from '../assets/images/gallery/buffe-6.jpg'
import Image13 from '../assets/images/gallery/room-view-2.jpg'
import Image14 from '../assets/images/gallery/room-view.jpg'
import Image15 from '../assets/images/gallery/bed-view.jpg'

import { Gallery, Item } from 'react-photoswipe-gallery'

import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from "react-lazy-load-image-component";

const images = [
    {
        original: Image1,
        thumbnail: Image1,
    },
    {
        original: Image2,
        thumbnail: Image2,
    },
    {
        original: Image3,
        thumbnail: Image3,
    },
    {
        original: Image4,
        thumbnail: Image4,
    },
    {
        original: Image5,
        thumbnail: Image5,
    },
    {
        original: Image7,
        thumbnail: Image7,
    },
    {
        original: Image8,
        thumbnail: Image8,
    },
    {
        original: Image9,
        thumbnail: Image9,
    },
    {
        original: Image10,
        thumbnail: Image10,
    },
    {
        original: Image11,
        thumbnail: Image11,
    },
    {
        original: Image12,
        thumbnail: Image12,
    },
    {
        original: Image13,
        thumbnail: Image13,
    },
    {
        original: Image14,
        thumbnail: Image14,
    },
    {
        original: Image15,
        thumbnail: Image15,
    }
]

const PhotoGallery = () => {
    return (
        <div>
            <div className="h-48 md:h-56 lg:h-96 -mt-20 sm:-mt-32 md:-mt-32 lg:-mt-96 xl:-mt-80 relative bg-cover bg-center w-full bg-no-repeat" style={window.innerWidth < 400 ? { backgroundImage: `url(${Image})`, backgroundSize: '210%' } : { backgroundImage: `url(${Image})`, backgroundSize: '100%' }}>
                <div className="absolute w-full h-full bg-gradient-to-t from-custom-dark to-custom-dark opacity-40 top-0 left-0 z-20" />
                <h1 className="absolute top-1/2 lg:top-2/3 left-0 right-0 mx-auto z-20 text-center text-2xl lg:text-4xl mt-5 text-shadow-3xl font-extrabold text-white">Gallery</h1>
            </div>

            <Gallery>
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6">
                    {images.map((img, id) => (
                        <div className="cursor-pointer gap-5 my-5 lg:my-10 px-10" key={id}>
                            <Item
                                original={img.original}
                                thumbnail={img.thumbnail}
                                width="1024"
                                height="768"
                            >
                                {({ ref, open }) => (
                                    <div className="flex my-auto w-auto h-auto shadow-2xl overflow-hidden bg-white" ref={ref}>
                                        <LazyLoadImage effect='blur' onClick={open} src={img.original} alt="" className="h-full w-full" />
                                    </div>
                                )}
                            </Item>
                        </div>
                    ))}
                </div>
            </Gallery>
        </div>
    )
}

export default PhotoGallery
