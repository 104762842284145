import Banner from '../components/Banner'
import Services from '../components/Services'
import ServicesPart2 from '../components/ServicesPart2'
import Request from '../components/Request'
import HotelReviews from '../components/HotelReviews'

const Home = () => {
    return (
        <div>
            <section className="-mt-20 sm:-mt-28 md:-mt-32 h-xsBannerHeight lg:mb-10 lg:-mt-96 sm:h-smBannerHeight md:h-mdBannerHeight lg:h-lgBannerHeight 2xl:-mb-1 xl:h-xlBannerHeight 2xl:h-bannerHeight">
                <Banner />
            </section>

            <Services />

            <ServicesPart2 />

            <Request />

            <HotelReviews />
        </div>
    )
}

export default Home
