import GoogleMapReact from 'google-map-react'
import RoomIcon from '@material-ui/icons/Room';

const AnyReactComponent = ({ text }) => <div className="font-bold text-sm"><RoomIcon className="text-red-600" />{text}</div>;

const Map = () => {
    const location = {
        center: {
            lat: 36.3212434461185,
            lng: 28.202801761857327
        },
        zoom: 11
    }
    return (
        <div>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyDs16b3lXbgAv0JPYi4_t-j45ByVlKb3js' }}
                defaultCenter={location.center}
                defaultZoom={location.zoom}
            >
                <AnyReactComponent
                    lat={36.3212434461185}
                    lng={28.202801761857327}
                    text="Ladiko Suites"
                />
            </GoogleMapReact>
        </div>
    )
}

export default Map
