import Image1 from '../assets/images/homeBanner/banner1.webp'
import Image2 from '../assets/images/homeBanner/banner2.webp'
import Image3 from '../assets/images/homeBanner/banner3.webp'
import Image4 from '../assets/images/homeBanner/banner4.webp'
import Image5 from '../assets/images/homeBanner/banner5.webp'
import Image6 from '../assets/images/homeBanner/banner6.webp'
import Image7 from '../assets/images/homeBanner/banner7.webp'
import Image8 from '../assets/images/homeBanner/banner8.webp'
import { useEffect, useState } from "react";

const Banner = () => {
    const [current, setCurrent] = useState(1)

    const imageData = [
        {
            id: 1,
            banner: Image1,
            desc: "sala-pietra-restaurant"
        },
        {
            id: 2,
            banner: Image2,
            desc: 'sala-pietra-restaurant'
        },
        {
            id: 3,
            banner: Image3,
            desc: 'hotel-reception'
        },
        {
            id: 4,
            banner: Image4,
            desc: 'hotel-stairs'
        },
        {
            id: 5,
            banner: Image5,
            desc: "reception-view"
        },
        {
            id: 6,
            banner: Image6,
            desc: 'bar-view'
        },
        {
            id: 7,
            banner: Image7,
            desc: 'ladiko-suites-at-night'
        },
        {
            id: 8,
            banner: Image8,
            desc: 'sala-pietra-at-night'
        }
    ]

    useEffect(() => {
        if (current <= 8) {
            setTimeout(() => {
                setCurrent(current + 1)
            }, 8000)
        } else {
            setCurrent(1)
        }
    }, [current])

    return (
        <div className="relative">
            <div className="absolute 2xl:w-full 2xl:h-full bg-gradient-to-t from-custom-dark to-custom-dark opacity-30 top-0 lg:bottom-0 z-20" />
            <div className="container-slider">
                {imageData.map((img, index) => (
                    <div className={current === index + 1 ? "slide active-anim" : "slide"} key={img.id}>
                        <div className='relative h-full w-full'>
                            <img src={img.banner} alt={img.desc} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Banner
