import "@material-tailwind/react/tailwind.css";
import Header from "./components/Header";
import { Route, Switch } from 'react-router-dom'
import Rooms from "./pages/Rooms";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import About from "./pages/About";
import SalaPietra from "./pages/SalaPietra";
import Home from "./pages/Home";
import BgImage from './assets/images/bg-font.webp'
import Footer from './components/Footer'
import RoomRequest from "./components/RoomRequest";
import { useState, useEffect } from "react";
import ReviewModal from "./components/ReviewModal";
import WarningIcon from '@material-ui/icons/Warning';
import { useTranslation } from 'react-i18next';
import EngBanner from './assets/images/e-banner.jpg';
import GreekBanner from './assets/images/e-bannergr.jpg';

function App() {
    const [roomType, setRoomType] = useState([])
    const [lang, setLang] = useState('ENG')
    const [open, setOpen] = useState(false)
    const [submit, setSubmit] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        console.log(lang)
    }, [lang])
    return (
        <div className="font-MainFont" style={{ backgroundImage: `url(${BgImage})` }}>

            <Header setLangState={(data) => setLang(data)} />

            <main>
                <Switch>
                    <Route path="/rooms">
                        <Rooms setRoomType={(room) => setRoomType(room)} />
                    </Route>
                    <Route path="/salapietra">
                        <SalaPietra />
                    </Route>
                    <Route path="/gallery">
                        <Gallery />
                    </Route>
                    <Route path="/contact">
                        <Contact />
                    </Route>
                    <Route path="/about">
                        <About />
                    </Route>
                    <Route path="/roomreq">
                        <RoomRequest roomType={roomType} />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
            </main>

            <Footer setShow={() => setOpen(true)} show={open} />

            {open && <ReviewModal show={open} setShow={() => setOpen(false)} setSubmit={() => setSubmit(true)} />}

            {submit && (
                <div className="fixed top-0 left-0 right-0 mx-auto w-52 bg-green-600 z-50">
                    <h1 className="text-white flex items-center text-2xl p-3"><WarningIcon className="mr-3" />{t('Review Sent!')}</h1>
                </div>
            )}

            <div className="fixed flex bottom-0 bg-white w-full z-40 opacity-90">
                <div className="mx-auto">
                    <a href={lang === 'GR' ? 'https://firebasestorage.googleapis.com/v0/b/ladiko-suites-5050a.appspot.com/o/%CE%91%CF%86%CE%AF%CF%83%CE%B1%20%CE%95%CE%BB%CE%BB%CE%B7%CE%BD%CE%B9%CE%BA%CE%AC.pdf?alt=media&token=febcd387-272c-46f9-a655-ced3cf82fd0c' : 'https://firebasestorage.googleapis.com/v0/b/ladiko-suites-5050a.appspot.com/o/%CE%91%CF%86%CE%AF%CF%83%CE%B1%20%CE%91%CE%B3%CE%B3%CE%BB%CE%B9%CE%BA%CE%AC.pdf?alt=media&token=9c1c8ac0-a524-4ece-9fd6-a1dcba544422'} target="_blank"><img src={lang === 'GR' ? GreekBanner : EngBanner} width={500} alt="banner" /></a>
                </div>
            </div>
        </div>
    );
}

export default App;
