import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal'
import { db } from '../firebase';
import firebase from 'firebase';

const customStyles = {
    overlay: {
        background: "#3A3138",
        opacity: '95%',
        zIndex: 30
    }
};

const ReviewModal = ({ show, setShow, setSubmit }) => {
    const { t } = useTranslation()
    const [name, setName] = useState('')
    const [subject, setSubject] = useState('')
    const [review, setReview] = useState('')


    const submitHandler = (e) => {
        e.preventDefault()

        if (name === '' || subject === '' || review === '') return alert('Please fill in all fields')

        db.collection('reviews').add({
            name,
            subject,
            review,
            key: 'reviewLadikoSuitesWebsite',
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
        })

        setShow(false)

        setSubmit(true)
    }

    return (
        <div>
            <Modal
                isOpen={show}
                onRequestClose={() => setShow(false)}
                className="fixed top-1/2 left-1/2 right-auto bottom-auto z-50 bg-white transform -translate-x-1/2 -translate-y-64 lg:-translate-y-1/2 w-72 lg:w-auto lg:p-5 outline-none rounded-xl max-h-screen overflow-y-auto"
                contentLabel="Example Modal"
                style={customStyles}
                scrollable={true}
            >
                <div className="p-3 flex flex-col space-y-5">
                    <h1 className="text-center font-semibold">{t('Add your review')}</h1>
                    <input type="text" className="bg-gray-300 p-3 placeholder-black outline-none" onChange={(e) => setName(e.target.value)} placeholder="Name" />
                    <input type="text" className="bg-gray-300 p-3 placeholder-black outline-none" onChange={(e) => setSubject(e.target.value)} placeholder="Subject" />
                    <textarea type="text" className="bg-gray-300 p-3 placeholder-black outline-none" onChange={(e) => setReview(e.target.value)} placeholder="Review" />
                    <button className="p-5 mx-auto uppercase text-white focus:outline-none bg-custom-button rounded-2xl w-44 font-extrabold transform duration-300 ease-in-out hover:bg-custom-dark" onClick={submitHandler}>{t('Submit')}</button>
                </div>
            </Modal>
        </div>
    )
}

export default ReviewModal
