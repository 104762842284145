import Image from '../assets/images/salapietra/sala-pietra.jpg'
import Logo from '../assets/images/pagephotos/sala pietra.jpg'

import Image1 from '../assets/images/salapietra/sala-pietra.jpg'
import Image2 from '../assets/images/salapietra/sala-pietra-ouside.jpg'
import Image3 from '../assets/images/salapietra/sala-pietra-outside-2.jpg'
import Image4 from '../assets/images/salapietra/plate-presentation-1.jpg'
import Image5 from '../assets/images/salapietra/plate-1.jpg'
import Image6 from '../assets/images/salapietra/food-on-the-table.jpg'
import Image7 from '../assets/images/salapietra/food-presentation-3.jpg'
import Image8 from '../assets/images/salapietra/food-on-the-table-2.jpg'
import Image9 from '../assets/images/salapietra/food-presentation-4.jpg'
import Image10 from '../assets/images/salapietra/food-plate.jpg'
import Image11 from '../assets/images/salapietra/drinks.jpg'
import Image12 from '../assets/images/salapietra/coctails.jpg'

import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from "react-lazy-load-image-component";

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { Gallery, Item } from 'react-photoswipe-gallery'
import { useTranslation } from 'react-i18next'

const images = [
    {
        original: Image1,
        thumbnail: Image1,
        alt: 'sala-pietra-restaurant'
    },
    {
        original: Image2,
        thumbnail: Image2,
        alt: 'sala-pietra-outside'
    },
    {
        original: Image3,
        thumbnail: Image3,
        alt: 'sala-pietra-outside-tables'
    },
    {
        original: Image4,
        thumbnail: Image4,
        alt: 'food-presentation'
    },
    {
        original: Image5,
        thumbnail: Image5,
        alt: 'food-plate'
    },
    {
        original: Image6,
        thumbnail: Image6,
        alt: 'food-plate'
    },
    {
        original: Image7,
        thumbnail: Image7,
        alt: 'food-presentation'
    },
    {
        original: Image8,
        thumbnail: Image8,
        alt: 'food-plate'
    },
    {
        original: Image9,
        thumbnail: Image9,
        alt: 'food-presentation'
    },
    {
        original: Image10,
        thumbnail: Image10,
        alt: 'food-plate'
    },
    {
        original: Image11,
        thumbnail: Image11,
        alt: 'special-cocktails'
    },
    {
        original: Image12,
        thumbnail: Image12,
        alt: 'special-drinks'
    }
]

const SalaPietra = () => {
    const { t } = useTranslation()
    return (
        <div>
            <div className="h-48 md:h-56 lg:h-96 -mt-20 sm:-mt-32 md:-mt-32 lg:-mt-96 xl:-mt-80 relative bg-cover bg-center w-full bg-no-repeat" style={window.innerWidth < 400 ? { backgroundImage: `url(${Image})`, backgroundSize: '210%' } : { backgroundImage: `url(${Image})`, backgroundSize: '100%' }}>
                <div className="absolute w-full h-full bg-gradient-to-t from-custom-dark to-custom-dark opacity-40 top-0 left-0 z-20" />
                <h1 className="absolute top-1/2 lg:top-2/3 left-0 right-0 mx-auto z-20 text-center text-2xl lg:text-4xl mt-5 text-shadow-3xl font-extrabold text-white">Sala Pietra Restaurant</h1>
            </div>
            <div className="bg-white text-center pb-10 sm:w-2/3 xl:max-w-6xl mx-auto shadow-2xl">
                <div className="grid xl:grid-cols-3">
                    <div className="py-10 xl:py-28 mx-auto space-y-5">
                        <h1 className="text-custom-button font-extrabold text-2xl lg:text-3xl">Sala Pietra.</h1>
                        <h1 className="text-2xl lg:text-3xl w-44">{t('Where the flavor inebriates you.')}
                        </h1>
                    </div>
                    <div>
                        <p className="py-10 px-10 xl:py-28 text-justify text-lg md:text-xl">
                            {t('In our newly built restaurant. SALA PIETRA, you can enjoy our nice and rich breakfast. At noon and in the evening our executive Chef Vasilis invites you to join a world of new flavors, tasting his delicious and unique dishes and enjoy the majestic atmosphere. Our waiters, Antonis and Katerina are there for you, their impeccable service will make you enjoy your food even better and last but not least our Barman George, will enchant you with his excellent, tasty and fantastic cocktails.')}
                        </p>
                    </div>
                    <img src={Logo} alt="" className="py-10 xl:py-28 mx-auto w-40 lg:w-56" />
                </div>
                <Gallery>
                    <div className="grid md:grid-cols-3 lg:grid-cols-3">
                        {images.map((img, id) => (
                            <div className="cursor-pointer gap-5 my-10 px-10" key={id}>
                                <Item
                                    original={img.original}
                                    thumbnail={img.thumbnail}
                                    width="1024"
                                    height="768"
                                >
                                    {({ ref, open }) => (
                                        <div className="w-auto h-auto flex my-auto shadow-xl bg-white" ref={ref}>
                                            <LazyLoadImage effect='blur' onClick={open} src={img.original} alt={img.alt} />
                                        </div>
                                    )}
                                </Item>
                            </div>
                        ))}
                    </div>
                </Gallery>
            </div>
        </div>
    )
}

export default SalaPietra
