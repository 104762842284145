import { useEffect } from "react"
import { useState } from "react"
import { db } from '../firebase'

import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import '../App.css'

const HotelReviews = () => {
    const [reviews, setReviews] = useState([])
    const [current, setCurrent] = useState(1)

    useEffect(() => {
        db.collection('reviews').onSnapshot(snapshot => setReviews(snapshot.docs.map(doc => ({
            id: doc.id,
            data: doc.data()
        }))))

        if (current <= reviews.length) {
            setTimeout(() => {
                setCurrent(current + 1)
            }, 6000)
        } else {
            setCurrent(1)
        }
    }, [current, reviews.length])

    return (
        <div className="h-96 sm:h-80 relative bg-white">
            <div className="py-10">
                {reviews.map(({ id, data: { review, name, title } }, index) => (
                    <div key={id} className={current === index + 1 ? "slide active-anim space-y-5 px-5" : "slide space-y-5 px-5"}>
                        <p className="lg:text-lg xl:text-xl text-center"><PermIdentityIcon fontSize="large" />{name}</p>
                        <p className="lg:text-lg xl:text-2xl text-shadow-2xl font-normal text-center">{title}</p>
                        <p className="lg:text-lg xl:text-xl text-justify italic md:w-2/3 mx-auto">"{review}"</p>
                    </div>
                ))}

                <div className='container-dots'>
                    {Array.from({ length: reviews.length }).map((item, index) => (
                        <div key={index}
                            className={current === index + 1 ? "dot active" : "dot"} />
                    ))}
                </div>
            </div>
        </div >
    )
}

export default HotelReviews
