import WifiIcon from '@material-ui/icons/Wifi';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import PoolIcon from '@material-ui/icons/Pool';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import StorefrontIcon from '@material-ui/icons/Storefront';
import { useTranslation } from 'react-i18next';

const ServicesPart2 = () => {
    const { t } = useTranslation()
    return (
        <div className="bg-white p-10">
            <h1 className="text-center text-2xl lg:text-3xl font-bold">{t('Why Choose Us')}</h1>
            <p className="text-center py-10 lg:text-xl lg:w-1/3 mx-auto">{t('We provide to our customers only the best services.')}</p>
            <div className="sm:px-10 xl:px-40 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-10">
                <div className="flex flex-col mx-auto items-center rounded-full justify-center shadow-2xl w-32 h-32 2xl:h-auto 2xl:w-40 2xl:p-10 border border-yellow-600 lg:space-y-5 transform duration-300 transition ease-in-out hover:scale-110 hover:border-yellow-800">
                    <WifiIcon fontSize="large" className="text-custom-button" />
                    <p className="font-bold">Internet</p>
                </div>
                <div className="flex flex-col items-center mx-auto rounded-full justify-center shadow-2xl w-32 h-32 2xl:h-auto 2xl:w-40 2xl:p-10 border border-yellow-600 space-y-5 transform duration-300 transition ease-in-out hover:scale-110 hover:border-yellow-800">
                    <LocalBarIcon fontSize="large" className="text-custom-button" />
                    <p className="font-bold">{t('Drinks')}</p>
                </div>
                <div className="flex flex-col items-center mx-auto rounded-full justify-center shadow-2xl w-32 h-32 2xl:h-auto 2xl:w-40 2xl:p-10 border border-yellow-600 space-y-5 transform duration-300 transition ease-in-out hover:scale-110 hover:border-yellow-800">
                    <PoolIcon fontSize="large" className="text-custom-button" />
                    <p className="font-bold">{t('Pool')}</p>
                </div>
                <div className="flex flex-col items-center mx-auto rounded-full justify-center shadow-2xl w-32 h-32 2xl:h-auto 2xl:w-40 2xl:p-10 border border-yellow-600 space-y-5 transform duration-300 transition ease-in-out hover:scale-110 hover:border-yellow-800">
                    <RestaurantMenuIcon fontSize="large" className="text-custom-button" />
                    <p className="font-bold">{t('Restaurant')}</p>
                </div>
                <div className="flex flex-col items-center mx-auto rounded-full justify-center shadow-2xl w-32 h-32 2xl:h-auto 2xl:w-40 2xl:p-10 border border-yellow-600 space-y-5 transform duration-300 transition ease-in-out hover:scale-110 hover:border-yellow-800">
                    <StorefrontIcon fontSize="large" className="text-custom-button" />
                    <p className="font-bold">{t('Supermarket')}</p>
                </div>
            </div>
        </div>
    )
}

export default ServicesPart2
