import Image1 from '../assets/images/roomCarousel/bed-front.jpg'
import Image2 from '../assets/images/roomCarousel/bed-side-left.jpg'
import Image3 from '../assets/images/roomCarousel/bed-side-right.jpg'
import Image4 from '../assets/images/roomCarousel/bathroom.jpg'
import Image5 from '../assets/images/roomCarousel/bathroom-2.jpg'
import Image6 from '../assets/images/roomCarousel/bath.jpg'
import Image7 from '../assets/images/roomCarousel/jacuzzi-view.jpg'
import Image8 from '../assets/images/roomCarousel/jacuzzi-view-2.jpg'
import Image9 from '../assets/images/roomCarousel/room-view.jpg'
import Image10 from '../assets/images/roomCarousel/living-room.jpg'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AirlineSeatIndividualSuiteIcon from '@material-ui/icons/AirlineSeatIndividualSuite';

const Rooms = ({ setRoomType }) => {
    const history = useHistory()
    const [current, setCurrent] = useState(1)
    const { t } = useTranslation()

    const imageData = [
        {
            id: 1,
            banner: Image1,
            desc: "suite-bed-left-side"
        },
        {
            id: 2,
            banner: Image2,
            desc: 'suite-bed-right-side'
        },
        {
            id: 3,
            banner: Image3,
            desc: 'bathroom'
        },
        {
            id: 4,
            banner: Image4,
            desc: 'bathroom-shower'
        },
        {
            id: 5,
            banner: Image5,
            desc: "bathroom-mirror"
        },
        {
            id: 6,
            banner: Image6,
            desc: 'jacuzzi-view'
        },
        {
            id: 7,
            banner: Image7,
            desc: 'big-jacuzzi-view'
        },
        {
            id: 8,
            banner: Image8,
            desc: 'balcony-view'
        },
        {
            id: 9,
            banner: Image9,
            desc: 'living-room'
        },
        {
            id: 10,
            banner: Image10,
            desc: 'living-room'
        }
    ]

    useEffect(() => {
        if (current <= 10) {
            setTimeout(() => {
                setCurrent(current + 1)
            }, 9000)
        } else {
            setCurrent(1)
        }
    }, [current])

    const request = (suites) => {
        setRoomType(suites)
        history.push('roomreq')
    }

    const suitesFor2 = {
        type: 'Suite for 2',
        persons: 2,
        bed: '2 single',
        space: '25 sq.m.'
    }
    const suitesFor3 = {
        type: 'Suite for 3',
        persons: 3,
        bed: '1 king 1 single',
        space: '30 sq.m.'
    }
    const familySuite = {
        type: 'Family Suite',
        persons: 4,
        bed: '1 king 1 sofa bed',
        space: '55 sq.m.'
    }

    return (
        <div className="relative">
            <div className="container-slider">
                {imageData.map((img, index) => (
                    <div className={current === index + 1 ? "slide active-anim" : "slide"} key={img.id}>
                        <div className='relative h-full w-full'>
                            <img src={img.banner} alt={img.desc} />
                        </div>
                    </div>
                ))}
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-2 xl:gap-5 sm:w-2/3 md:w-full xl:w-5/6 mx-auto z-40 py-5">
                <div className="bg-white shadow h-auto p-8 space-y-5 text-center">
                    <div className='h-32 space-y-5'>
                        <h1 className="text-center text-2xl font-extrabold">{t('Junior Suites for 2')}</h1>
                        <p>{t('Deluxe suites with private pool, 2 single beds or 1 king bed and sea view.')} </p>
                    </div>
                    <div className="border-b px-10" />
                    <div className="flex items-center justify-between">
                        <p className="items-center lg:flex lg:flex-col 2xl:flex-row"><PeopleAltIcon className="text-custom-button" /> 2</p>
                        <p className="lg:flex lg:flex-col 2xl:flex-row"><AirlineSeatIndividualSuiteIcon className="text-custom-button" /> 2 {t('single')}</p>
                        <p>or</p>
                        <p className="lg:flex lg:flex-col 2xl:flex-row"><AirlineSeatIndividualSuiteIcon className="text-custom-button" /> 1 {t('king')}</p>
                        <p className="lg:flex lg:flex-col 2xl:flex-row"><AspectRatioIcon className="text-custom-button" /> 25</p>
                        <button className='bg-blue-400 p-2 text-white rounded-sm shadow hover hover:bg-blue-700 transform duration-200 ease-in' id={1} onClick={() => request(suitesFor2)}>{t('Request')}</button>
                    </div>
                </div>
                <div className="bg-white shadow p-8 space-y-5 text-center col-span-2 md:col-span-1">
                    <div className='h-32 space-y-5'>
                        <h1 className="text-center text-2xl font-extrabold">{t('Junior Suites for 2')}</h1>
                        <p className="pb-6">{t('Spacious sea view suites with 1 king bed')}</p>
                    </div>
                    <div className="border-b px-10" />
                    <div className="flex items-center justify-between">
                        <p className="items-center lg:flex lg:flex-col 2xl:flex-row"><PeopleAltIcon className="text-custom-button" /> 2</p>
                        <p className="lg:flex lg:flex-col 2xl:flex-row"><AirlineSeatIndividualSuiteIcon className="text-custom-button" /> 1 {t('king')} </p>
                        <p className="lg:flex lg:flex-col 2xl:flex-row"><AspectRatioIcon className="text-custom-button" /> 30</p>
                        <button className='bg-blue-400 p-2 text-white rounded-sm shadow hover hover:bg-blue-700 transform duration-200 ease-in' id={2} onClick={() => request(suitesFor3)}>{t('Request')}</button>
                    </div>
                </div>
                <div className="bg-white shadow p-8 space-y-5 text-center col-span-2 lg:col-span-1">
                    <div className='h-32 space-y-5'>
                        <h1 className="text-center text-2xl font-extrabold">{t('Family Suites')}</h1>
                        <p>{t('Mount and Sea view suites with big balcony and jacuzzi. 1 king bed and 1 sofa-bed.')}</p>
                    </div>
                    <div className="border-b px-10" />
                    <div className="flex items-center justify-between">
                        <p className="items-center lg:flex lg:flex-col 2xl:flex-row"><PeopleAltIcon className="text-custom-button" /> 4</p>
                        <p className="lg:flex lg:flex-col 2xl:flex-row"><AirlineSeatIndividualSuiteIcon className="text-custom-button" /> 1 {t('king')}</p>
                        <p className="text-2xl">+</p>
                        <p className="lg:flex lg:flex-col 2xl:flex-row"><AirlineSeatIndividualSuiteIcon className="text-custom-button" /> 1 {t('sofa bed')}</p>
                        <p className="lg:flex lg:flex-col 2xl:flex-row"><AspectRatioIcon className="text-custom-button" /> 55</p>
                        <button className='bg-blue-400 p-2 text-white rounded-sm shadow hover hover:bg-blue-700 transform duration-200 ease-in' id={3} onClick={() => request(familySuite)}>{t('Request')}</button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Rooms
