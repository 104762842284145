import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const Footer = ({ setShow }) => {
    const history = useHistory()
    const { t } = useTranslation()
    return (
        <div className="bg-custom-dark lg:px-32 xl:px-56 2xl:px-96 text-center text-white relative">
            <div className="flex flex-col">
                <div className="lg:grid lg:grid-cols-2 xl:space-y-10 p-5">
                    <div className="flex flex-col p-5 mt-5 xl:p-10 lg:w-96">
                        <h1 className="mb-2 text-lg xl:text-2xl">Ladiko Suites</h1>
                        <p className="text-sm">{t('We make the ordinary, extraordinary.')}</p>
                        <button className="border border-custom-button font-bold mt-3 p-3 w-40 mx-auto my-auto transform duration-300 ease-in-out hover:bg-custom-button focus:outline-none" onClick={() => setShow(true)}>{t('Add Review')}</button>
                    </div>
                    <div className="flex flex-row lg:flex-none lg:flex 2xl:-ml-28 3xl:-ml-72 mb-5 lg:mb-0">
                        <div className="flex flex-col space-y-1 mx-auto text-sm xl:text-base">
                            <h1 className="text-left mb-2 text-xl xl:text-2xl">{t('About')}</h1>
                            <p className="flex items-center cursor-pointer" onClick={() => history.push('/')}><ArrowForwardIosIcon />{t('Home')}</p>
                            <p className="flex items-center cursor-pointer" onClick={() => history.push('/rooms')}><ArrowForwardIosIcon />{t('Rooms')}</p>
                            <p className="flex items-center cursor-pointer" onClick={() => history.push('/gallery')}><ArrowForwardIosIcon />{t('Gallery')}</p>
                            <p className="flex items-center cursor-pointer" onClick={() => history.push('/about')}><ArrowForwardIosIcon />{t('About us')}</p>
                            <p className="flex items-center cursor-pointer" onClick={() => history.push('/contact')}><ArrowForwardIosIcon />{t('Contact')}</p>
                        </div>
                        <div className="space-y-2 flex flex-col w-56 lg:w-auto text-sm xl:text-base">
                            <h1 className="text-xl xl:text-2xl">{t('Contact Us')}</h1>
                            <p className="mb-2">Ladiko, Faliraki, {t('next to Antony Quinn bay')}</p>
                            <a href="tel:00302241085636">Tel: +30 2241 0 85636</a>
                            <a href="tel:00306932377540">Tel: +30 693 23 77540</a>
                            <a href="mailto:info@ladikosuites.com">info@ladikosuites.com</a>
                            <a href="mailto:info@salapietra.com">info@salapietra.com</a>
                        </div>
                    </div>
                    <div className="col-span-3 flex-col flex space-y-5">
                        <div className="border-b border-white" />
                        <div className="text-center sm:text-xs lg:text-base">Copyright © 2021 Created by LC WEB CREATIONS</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
