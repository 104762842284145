import { useState } from 'react'
import Image from '../assets/images/pagephotos/requestBG.jpg'
import { db } from '../firebase'
import firebase from 'firebase'
import Modal from 'react-modal'
import Logo from '../assets/images/logo.jpg'
import { useTranslation } from 'react-i18next';

const customStyles = {
    overlay: {
        background: "#3A3138",
        opacity: '95%',
        zIndex: 30
    }
};

const RoomRequest = ({ roomType: { type, persons, bed, space } }) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [checkin, setCheckin] = useState('')
    const [checkout, setCheckout] = useState('')
    const [tel, setTel] = useState('')
    const [msg, setMsg] = useState('')
    const [flag1, setFlag1] = useState(false)
    const [flag2, setFlag2] = useState(false)
    const [alert, setAlert] = useState(false)
    const [submit, setSubmit] = useState(false)
    const { t } = useTranslation()

    const submitHandler = (e) => {
        e.preventDefault()

        if (name === '' || email === '' || checkin === '' || checkout === '' || msg === '' || tel === '') return setAlert(true)

        setSubmit(true)

        db.collection('roomRequest').add({
            name,
            type,
            persons,
            email,
            checkin,
            checkout,
            tel,
            msg,
            key: 'roomRequestLadikoSuitesWebsite',
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
        })

        setName('')
        setEmail('')
        setFlag1(false)
        setFlag2(false)
        setMsg('')
        setTel('')
        setCheckout('')
        setCheckin('')
    }




    return (
        <div>
            <div className="h-48 md:h-56 lg:h-96 -mt-20 sm:-mt-32 md:-mt-32 lg:-mt-96 xl:-mt-80 relative bg-cover bg-center w-full bg-no-repeat" style={window.innerWidth < 400 ? { backgroundImage: `url(${Image})`, backgroundSize: '210%' } : { backgroundImage: `url(${Image})`, backgroundSize: '100%' }}>
                <div className="absolute w-full h-full bg-gradient-to-t from-custom-dark to-custom-dark opacity-40 top-0 left-0 z-20" />
                <h1 className="absolute top-1/2 lg:top-2/3 left-0 right-0 mx-auto z-20 text-center text-2xl lg:text-4xl mt-5 text-shadow-3xl font-extrabold text-white">{t('Room Request')}</h1>
            </div>
            <div className="bg-white text-center sm:w-2/3 lg:space-y-8 pb-10 max-w-6xl mx-auto shadow-2xl">
                <form className="px-10 lg:px-20 xl:px-40 2xl:px-64 pt-5 flex flex-col space-y-10">
                    <div className="flex flex-col items-center bg-custom-dark opacity-90 p-2 shadow-xl text-white space-y-3">
                        <h1 className="uppercase font-extrabold">{t('Suite Details')}</h1>
                        <div className="flex flex-col lg:flex-row items-center space-x-5">
                            <p><b>{t('Suite')}:</b> {type}</p>
                            <p><b>{t('Guests')}:</b> {persons}</p>
                            <p><b>{t('Suite space')}:</b> {space}</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-5 pt-2">
                        <input type="text" className="bg-gray-300 p-3 placeholder-black outline-none" onChange={(e) => setName(e.target.value)} placeholder={t("Name")} value={name} />
                        <input type="email" className="bg-gray-300 p-3 placeholder-black outline-none" onChange={(e) => setEmail(e.target.value)} placeholder={t("Email")} value={email} />
                    </div>
                    <div className="grid grid-cols-2 gap-5">
                        <input type={flag1 ? "date" : "text"} onFocus={() => setFlag1(!flag1)} className="bg-gray-300 p-3 w-full placeholder-black outline-none" value={checkin} onChange={(e) => setCheckin(e.target.value)} placeholder={t("Check-In")} />
                        <input type={flag2 ? "date" : "text"} onFocus={() => setFlag2(!flag2)} className="bg-gray-300 p-3 w-full placeholder-black outline-none" value={checkout} onChange={(e) => setCheckout(e.target.value)} placeholder={t("Check-Out")} />
                    </div>
                    <input type="number" className="bg-gray-300 p-3 placeholder-black outline-none" onChange={(e) => setTel(e.target.value)} value={tel} placeholder={t("Telephone")} />

                    <textarea type="text" className="bg-gray-300 p-3 h-40 placeholder-black outline-none" onChange={(e) => setMsg(e.target.value)} value={msg} placeholder={t("extra request")} />

                    <button className="p-5 mx-auto uppercase w-auto text-white focus:outline-none bg-custom-button rounded-2xl font-extrabold transform duration-300 ease-in-out hover:bg-custom-dark" onClick={submitHandler}>{t('Send Request')}</button>
                </form>
            </div>

            {alert && (
                <Modal
                    isOpen={alert}
                    onRequestClose={() => setAlert(false)}
                    className="fixed top-1/2 left-1/2 right-auto bottom-auto bg-white transform -translate-x-1/2 -translate-y-64 lg:-translate-y-1/2 w-72 lg:w-auto lg:p-5 outline-none z-20 rounded-xl max-h-screen overflow-y-auto"
                    contentLabel="Example Modal"
                    style={customStyles}
                    scrollable={true}
                >
                    <div className="p-3">
                        <img src={Logo} alt="Ladiko Suites Logo" className="h-20 mx-auto" />
                        <h1 className="py-4 font-bold text-center">{t('Please fill in all input fields!')}</h1>
                    </div>
                </Modal>
            )}
            {submit && (
                <Modal
                    isOpen={submit}
                    onRequestClose={() => setSubmit(false)}
                    className="fixed top-1/2 left-1/2 right-auto bottom-auto bg-white transform -translate-x-1/2 -translate-y-64 lg:-translate-y-1/2 w-72 lg:w-auto lg:p-5 outline-none z-20 rounded-xl max-h-screen overflow-y-auto"
                    contentLabel="Example Modal"
                    style={customStyles}
                    scrollable={true}
                >
                    <div className="p-3">
                        <img src={Logo} alt="Ladiko Suites Logo" className="h-20 mx-auto" />
                        <h1 className="py-4 font-bold text-center">{t('Room Request Sent!')}</h1>
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default RoomRequest
