import Image from '../assets/images/pagephotos/contactBG.jpg'
import PhoneIcon from '@material-ui/icons/Phone';
import RoomIcon from '@material-ui/icons/Room';
import Map from '../components/Map'
import EmailIcon from '@material-ui/icons/Email';
import { useState } from 'react';
import { db } from '../firebase';
import firebase from 'firebase'
import Modal from 'react-modal'
import Logo from '../assets/images/logo.jpg'
import { useTranslation } from 'react-i18next';

const customStyles = {
    overlay: {
        background: "#3A3138",
        opacity: '95%',
        zIndex: 30
    }
};

const Contact = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [msg, setMsg] = useState('')
    const [tel, setTel] = useState('')
    const [subject, setSubject] = useState('')
    const [alert, setAlert] = useState(false)
    const [submit, setSubmit] = useState(false)
    const { t } = useTranslation()

    const submitHandler = (e) => {
        e.preventDefault()

        if (name === '' || email === '' || subject === '' || msg === '' || tel === '') return setAlert(true)

        setSubmit(true)

        db.collection('contactMsg').add({
            name,
            email,
            subject,
            tel,
            msg,
            key: 'contactMsgLadikoSuitesWebsite',
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
        })

        setName('')
        setEmail('')
        setMsg('')
        setTel('')
        setSubject('')
    }

    return (
        <div>
            <div className="relative h-48 md:h-56 lg:h-96 -mt-20 sm:-mt-32 md:-mt-32 lg:-mt-96 xl:-mt-80 bg-cover bg-center w-full bg-no-repeat" style={window.innerWidth < 400 ? { backgroundImage: `url(${Image})`, backgroundSize: '210%' } : { backgroundImage: `url(${Image})`, backgroundSize: '100%' }}>
                <div className="absolute w-full h-full bg-gradient-to-t from-custom-dark to-custom-dark opacity-40 top-0 left-0 z-20" />
                <h1 className="absolute top-1/2 lg:top-2/3 left-0 right-0 mx-auto z-20 text-center text-2xl lg:text-4xl mt-5 text-shadow-3xl font-extrabold text-white">{t('Contact us')}</h1>
            </div>
            <div className="bg-white text-center sm:w-2/3 lg:space-y-8 pb-10 max-w-6xl mx-auto shadow-2xl">
                <h1 className="p-10 text-2xl xl:text-3xl font-extrabold">{t('Feedback Form')}</h1>
                <p className="px-5 xl:px-5 text-xl xl:text-2xl mb-5">{t('Please fill in the form below and we will reply back as soon as possible.')}</p>

                <form className="px-10 xl:px-36 2xl:px-64 flex flex-col space-y-10">
                    <div className="grid grid-cols-2 gap-5">
                        <input type="text" className="bg-gray-300 p-3 placeholder-black outline-none" placeholder={t("Name")} value={name} onChange={(e) => setName(e.target.value)} />
                        <input type="email" className="bg-gray-300 p-3 placeholder-black outline-none" placeholder={t("Email")} value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="grid grid-cols-2 gap-5">
                        <input type="text" className="bg-gray-300 p-3 placeholder-black outline-none" placeholder={t("Subject")} value={subject} onChange={(e) => setSubject(e.target.value)} />
                        <input type="number" className="bg-gray-300 p-3 placeholder-black outline-none" placeholder={t("Telephone")} value={tel} onChange={(e) => setTel(e.target.value)} />
                    </div>
                    <textarea type="text" className="bg-gray-300 p-3 h-40 placeholder-black outline-none" placeholder={t("Message")} value={msg} onChange={(e) => setMsg(e.target.value)} />

                    <button className="p-5 mx-auto uppercase text-white focus:outline-none bg-custom-button rounded-2xl w-auto font-extrabold transform duration-300 ease-in-out hover:bg-custom-dark" onClick={submitHandler}>{t('Send Message')}</button>
                </form>

                <div className="grid grid-rows-2 lg:grid-rows-1 lg:grid-cols-2 bg-custom-conbg lg:h-full mt-5 xl:mt-0">
                    <div className="flex flex-col py-5 xl:py-20 space-y-5">
                        <h1 className="text-2xl xl:text-3xl">{t('Contact')}</h1>
                        <a href='tel:00302241085636' className="xl:text-2xl"><PhoneIcon className="text-custom-button" /> +30 2241 0 85636</a>
                        <a href='tel:00306932377540' className="xl:text-2xl"><PhoneIcon className="text-custom-button" /> +30 693 23 77540</a>
                        <p className="xl:text-2xl"><RoomIcon className="text-custom-button" /> Ladiko, Faliraki, Antony Quinn Bay</p>
                        <a className="xl:text-2xl flex items-center mx-auto" href="mailto:info@ladikosuites.com"><EmailIcon className="text-custom-button mr-1" /> info@ladikosuites.com</a>
                        <a className="xl:text-2xl flex items-center mx-auto" href="mailto:info@salapietra.com"><EmailIcon className="text-custom-button mr-1" /> info@salapietra.com</a>
                    </div>
                    <Map />

                </div>
            </div>

            {alert && (
                <Modal
                    isOpen={alert}
                    onRequestClose={() => setAlert(false)}
                    className="fixed top-1/2 left-1/2 right-auto bottom-auto bg-white transform -translate-x-1/2 -translate-y-64 lg:-translate-y-1/2 w-72 lg:w-auto lg:p-5 outline-none z-20 rounded-xl max-h-screen overflow-y-auto"
                    contentLabel="Example Modal"
                    style={customStyles}
                    scrollable={true}
                >
                    <img src={Logo} alt="Ladiko Suites Logo" className="h-20 mx-auto" />
                    <h1 className="py-4 font-bold text-center">{t('Please fill in all input fields!')}</h1>
                </Modal>
            )}
            {submit && (
                <Modal
                    isOpen={submit}
                    onRequestClose={() => setSubmit(false)}
                    className="fixed top-1/2 left-1/2 right-auto bottom-auto bg-white transform -translate-x-1/2 -translate-y-64 lg:-translate-y-1/2 w-72 lg:w-auto lg:p-5 outline-none z-20 rounded-xl max-h-screen overflow-y-auto"
                    contentLabel="Example Modal"
                    style={customStyles}
                    scrollable={true}
                >
                    <img src={Logo} alt="Ladiko Suites Logo" className="h-20 mx-auto" />
                    <h1 className="py-4 font-bold text-center">{t('Message Sent!')}</h1>
                </Modal>
            )}



        </div>
    )
}

export default Contact
