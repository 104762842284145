import Image from '../assets/images/homeBanner/banner8.webp'
import Logo from '../assets/images/pagephotos/about-page-logo.jpg'

import Image1 from '../assets/images/pagephotos/about-photo-1.jpg'
import Image2 from '../assets/images/pagephotos/about-photo-2.jpg'
import Image3 from '../assets/images/salapietra/coctails.jpg'

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Gallery, Item } from 'react-photoswipe-gallery'
import { useState } from 'react'

import { db } from '../firebase'
import { useTranslation } from 'react-i18next'

const images = [
    {
        original: Image1,
        thumbnail: Image1,
        alt: 'Ladiko-Suites'
    },
    {
        original: Image2,
        thumbnail: Image2,
        alt: 'Ladiko-Reception'
    },
    {
        original: Image3,
        thumbnail: Image3,
        alt: 'restaurant-cocktail'
    }
]

const About = () => {
    const [video, setVideo] = useState('')
    const [load, setLoad] = useState(true)
    const { t } = useTranslation()

    db.collection('video').doc('aboutVideo').get().then(res => setVideo(res.data().video1))

    return (
        <div>
            <div className="h-48 md:h-56 lg:h-96 -mt-20 sm:-mt-32 md:-mt-32 lg:-mt-96 xl:-mt-80 relative bg-cover bg-center w-full bg-no-repeat" style={window.innerWidth < 400 ? { backgroundImage: `url(${Image})`, backgroundSize: '210%' } : { backgroundImage: `url(${Image})`, backgroundSize: '100%' }}>
                <div className="absolute w-full h-full bg-gradient-to-t from-custom-dark to-custom-dark opacity-40 top-0 left-0 z-20" />
                <h1 className="absolute top-1/2 lg:top-3/4 left-0 right-0 mt-5 mx-auto z-20 text-center text-2xl lg:text-4xl text-shadow-3xl font-extrabold text-white">{t('About')}</h1>
            </div>
            <div className="bg-white text-center sm:w-2/3 lg:space-y-8 lg:pb-10 xl:max-w-6xl mx-auto shadow-2xl">
                <div className="grid xl:grid-cols-3">
                    <div className="py-10 lg:py-28 mx-auto space-y-5">
                        <h1 className="text-custom-button font-extrabold text-2xl lg:text-3xl">Ladiko Suites.</h1>
                        <h1 className="text-2xl lg:text-3xl w-44">{t('Everything. Right where you need it.')}
                        </h1>
                    </div>
                    <div>
                        <p className="px-10 xl:px-0 lg:py-28 text-justify text-lg  md:text-xl">
                            {t('Our Hotel, is a family business, built in 1987, in the beautiful and romantic area of Ladiko Rhodes, also known as Anthony Quinn. A beautiful combination of mountain and sea, the most beautiful parts of the island, proof, numerous customers. From 1.7.2021, Diamant.AE manages Ladiko Suites. Six fully new junior suites, on the ground floor, for two people, with private pool sea view and three Suites of 53, sq.m, on the first floor with Jacuzzi and second bathroom, for four people, both with sea view and the another with mountain view. The bay of Ladiko is 150 m and that of Anthony Quinn, 500 m, where the Guns of Navaron movie was filmed. Ladiko Suites are away, 3 km. From the famous beach of Traounou, 3 km from the cosmopolitan Faliraki, with its beautiful sandy beach, 4 km, from Golf, 17 km. From Rhodes town, 20 km. From the airport and 35 km. from Lindos. For unforgettable vacation in a beautiful and friendly atmosphere, come to us, you expect. My family and I, we will be very happy to host you, we are waiting for you.')}
                        </p>
                    </div>
                    <div className="relative">
                        <img src={Logo} alt="logo" className={load ? "flex lg:py-28 mx-auto" : "hidden"} />
                        <video autoPlay loop preload={'auto'} className="mt-32 px-5" playsInline onPlaying={() => setLoad(false)} muted src={video} type="video/mp4" />
                    </div>

                </div>
                <div>
                    <Gallery>
                        <div className="grid lg:grid-cols-2 xl:grid-cols-3">
                            {images.map((img, id) => (
                                <div className="cursor-pointer 2xl:gap-5 my-3 xl:my-10 2xl:px-10 mx-auto" key={id}>
                                    <Item
                                        original={img.original}
                                        thumbnail={img.thumbnail}
                                        width="1024"
                                        height="768"
                                    >
                                        {({ ref, open }) => (
                                            <div className=" w-56 h-36 2xl:w-72 2xl:h-48 shadow-2xl overflow-hidden bg-white" ref={ref}>
                                                <LazyLoadImage effect='blur' onClick={open} src={img.original} alt={img.alt} className="h-full w-full" />
                                            </div>
                                        )}
                                    </Item>
                                </div>
                            ))}
                        </div>
                    </Gallery>
                </div>
            </div>
        </div>
    )
}

export default About
