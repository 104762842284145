import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyAQqeVVHUvZsqY8uA2KCrtfAR-6j6ly1Ac",
    authDomain: "ladiko-suites-5050a.firebaseapp.com",
    projectId: "ladiko-suites-5050a",
    storageBucket: "ladiko-suites-5050a.appspot.com",
    messagingSenderId: "730098548244",
    appId: "1:730098548244:web:a0ad256ca39c5367f77101"
};

const app = firebase.initializeApp(firebaseConfig)

const db = app.firestore()

export { db }