import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import { useHistory } from 'react-router-dom';
import Logo from '../assets/images/ls_logo.png'
import { useEffect, useState } from 'react';
import { XyzTransition } from '@animxyz/react'
import '@animxyz/core'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next'

const language = [
    {
        language: 'GR',
        lang: 'Ελληνικά',
        code: 'gr',
        countryCode: 'gr'
    },
    {
        language: 'ENG',
        lang: 'English',
        code: 'en',
        countryCode: 'gb'
    },
    {
        language: 'DE',
        lang: 'Deutch',
        code: 'de',
        countryCode: 'de'
    },
    {
        language: 'FR',
        lang: 'Francaise',
        code: 'fr',
        countryCode: 'fr'
    },
    {
        language: 'IT',
        lang: 'Italiano',
        code: 'it',
        countryCode: 'it'
    },
]

const Header = ({ setLangState }) => {
    const [show, setShow] = useState(false)
    const [langMenu, setLangMenu] = useState(false)
    const [lang, setLang] = useState('ENG')
    const history = useHistory()
    const { t } = useTranslation()

    useEffect(() => {
        i18next.changeLanguage('en')

        setLangState(lang)
    }, [lang])

    const mobileMenuHandler = (nav) => {
        history.push(nav)
        setShow(!show)
    }

    const langHandler = (foreignLang, langCode) => {
        i18next.changeLanguage(langCode)
        setLang(foreignLang)
        setLangMenu(!langMenu)
    }

    return (
        <div className="flex flex-col lg:p-10 text-white z-30 relative bg-transparent">
            {/* top nav menu */}
            <div className="flex lg:justify-between w-80 sm:w-auto xl:px-10">
                {/* Left side */}
                <div className="flex space-x-4 my-auto">
                    <p className="flex items-center cursor-pointer w-14 pl-5 lg:pl-0 z-20 text-black font-semibold lg:text-white lg:font-MainFont" onClick={() => setLangMenu(!langMenu)}>{lang}<ArrowDropDownIcon /></p>

                    <div className="border-r border-white hidden lg:flex" />
                    <div className="flex-col hidden lg:flex">
                        <a className="flex items-center" href="tel:00302241085636"><PhoneEnabledIcon className="mr-1" />+30 2241 0 85636</a>
                        <a className="flex items-center" href="tel:00306932377540"><PhoneEnabledIcon className="mr-1" />+30 693 23 77540</a>
                    </div>
                </div>
                {/* Middle section */}
                <img src={Logo} loading="lazy" height="auto" width="auto" className="flex mx-auto w-32 pt-8 md:pt-5 sm:w-48 md:w-52 lg:mt-0 sm:py-5 z-20 lg:w-64 sm:p-5 xl:p-0" alt="ladiko suites logo" />
                {/* Right section */}
                <div className="lg:flex space-x-4 my-auto hidden">
                    <p>Ladiko, Antony Quinn bay</p>
                    <div className="border-r border-white" />
                    <div className="flex items-center space-x-2">
                        <a href="https://www.facebook.com/ladikohotel" target="_black"><FacebookIcon className="cursor-pointer" /></a>
                        <a href="https://www.instagram.com/ladikosuites/?hl=en" target="_black"><InstagramIcon className="cursor-pointer" /></a>
                    </div>
                </div>
            </div>
            {/* bottom nav menu */}
            <div className="lg:flex uppercase items-center mx-auto p-10 font-semibold  space-x-20 hidden">
                <p className="cursor-pointer border-b-2 border-transparent hover:border-b-2 hover:border-white pb-3 transition duration-300 ease-in" onClick={() => history.push('/')}>{t('Home')}</p>
                <p className="cursor-pointer border-b-2 border-transparent hover:border-b-2 hover:border-white pb-3 transition duration-300 ease-in" onClick={() => history.push('/salapietra')}>{t('Sala Pietra')}</p>
                <p className="cursor-pointer border-b-2 border-transparent hover:border-b-2 hover:border-white pb-3 transition duration-300 ease-in" onClick={() => history.push('/rooms')}>{t('Rooms')}</p>
                <p className="cursor-pointer border-b-2 border-transparent hover:border-b-2 hover:border-white pb-3 transition duration-300 ease-in" onClick={() => history.push('/gallery')}>{t('Gallery')}</p>
                <p className="cursor-pointer border-b-2 border-transparent hover:border-b-2 hover:border-white pb-3 transition duration-300 ease-in" onClick={() => history.push('/about')}>{t('About')}</p>
                <p className="cursor-pointer border-b-2 border-transparent hover:border-b-2 hover:border-white pb-3 transition duration-300 ease-in" onClick={() => history.push('/contact')}>{t('Contact')}</p>
            </div>

            {!show ?
                <MenuIcon className="absolute block top-10 right-8 text-black lg:hidden h-8 shadow-xl" onClick={() => setShow(!show)} />
                :
                <XIcon className="absolute block top-10 right-8 text-black z-20 lg:hidden h-8" onClick={() => setShow(!show)} />
            }

            <XyzTransition xyz="fade">
                {show && (
                    <div className="absolute bg-white top-0 w-full h-screen">
                        <div className="absolute top-1/4 flex flex-col text-black space-y-10 w-full text-2xl font-extrabold">
                            <p className="mx-auto" onClick={() => mobileMenuHandler('/')}>{t('Home')}</p>
                            <p className="mx-auto" onClick={() => mobileMenuHandler('/salapietra')}>{t('Sala Pietra')}</p>
                            <p className="mx-auto" onClick={() => mobileMenuHandler('/rooms')}>{t('Rooms')}</p>
                            <p className="mx-auto" onClick={() => mobileMenuHandler('/gallery')}>{t('Gallery')}</p>
                            <p className="mx-auto" onClick={() => mobileMenuHandler('/about')}>{t('About')}</p>
                            <p className="mx-auto" onClick={() => mobileMenuHandler('/contact')}>{t('Contact')}</p>
                        </div>
                    </div>
                )}
            </XyzTransition>

            <XyzTransition xyz="fade">
                {langMenu && (
                    <div className="bg-white lg:bg-custom-dark lg:bg-opacity-30 fixed top-14 left-0 lg:top-32 lg:left-16 border border-gray-800">
                        {language.map((lang, index) => (
                            <div key={index} className="p-2 cursor-pointer flex" onClick={() => langHandler(lang.language, lang.code)}>
                                <span className={`flag-icon flag-icon-${lang.countryCode} mx-2`}></span>
                                <h1 className="text-black lg:text-white">{lang.lang}</h1>
                            </div>
                        ))}
                    </div>
                )}
            </XyzTransition>

        </div>
    )
}

export default Header