import { useHistory } from 'react-router-dom'
import BgImage from '../assets/images/pagephotos/request-bg.jpg'
import { useTranslation } from 'react-i18next';

const Request = () => {
    const history = useHistory()
    const { t } = useTranslation()
    return (
        <div className="h-96 relative lg:px-64 flex flex-col space-y-5 lg:space-y-10 text-white" style={{ backgroundImage: `url(${BgImage})`, backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <div className="absolute w-full h-full bg-gradient-to-t from-custom-dark to-custom-dark opacity-90 top-0 left-0 z-20" />
            <h1 className="text-center z-30 p-10 text-xl lg:text-3xl font-extrabold">{t('Make a request for a room')}</h1>
            <p className="text-center z-30 px-5 lg:px-0 text-sm lg:text-base">{t('Make a request for one of our luxurious suites and leave the rest to us! Ladiko suites - Share a Grand Experience')}</p>
            <button className="border lg:w-auto mx-auto border-custom-button p-3 text-xl lg:text-2xl font-extrabold z-30 transform duration-300 ease-in-out hover:bg-custom-button" onClick={() => history.push('/rooms')}>{t('Room Request')}</button>
        </div>
    )
}



export default Request
